<template>
  <div id="banner" :style="{ animationDuration: animationDuration + 's' }">
    <p>Try our Taster Pack, just £16 for 2 Sessions</p>
    <p>•</p>
    <p>Try our Taster Pack, just £16 for 2 Sessions</p>
    <p>•</p>
    <p>Try our Taster Pack, just £16 for 2 Sessions</p>
    <p v-if="mobile">•</p>
  </div>
</template>

<script>
export default {
  name: 'TasterPack',
  data() {
    return {
      mobile: window.innerWidth < 768,
      animationDuration: 10,
    }
  },
  mounted() {
    this.animationDuration = this.mobile ? 10 : 0;
  },
  watch: {
    mobile() {
      this.animationDuration = this.mobile ? 10 : 0;
    },
  },
}
</script>

<style lang="scss" scoped>
$aqua: #7fc0c3;
$charcoal: #231F20;
$carbon: #58595B;
$zest: #DFF496;
$midnight: #193446;

#banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: $midnight;
  padding: 20px 0;
  margin-top: 80px;

  p {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  #banner {
    display: flex;
    align-items: center;
    animation: scroll infinite linear;
    width: calc(250%);
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }
}
</style>