<template>
  <div id="footer">
    <div id="footer-container">
      <div>
        <img id="logo" src="../assets/logo_nav.png" alt="GYMPODS" style="width: 250px" />
        <p><a @click="openFaqs">FAQs</a></p>
        <p><a @click="contact">CONTACT US</a></p>
        <p><a @click="terms">T&Cs</a></p>
        <p><a @click="privacy">PRIVACY POLICY</a></p>
        <p>&copy; The FIT POD Company Ltd</p>
        <br />
      </div>

      <div>
        <h3 style="color: white">Follow</h3>
        <div id="socials">
          <a style="color: white" href="https://instagram.com/gympods/">
            <i style="color: white" class="fa-brands fa-instagram"></i></a>
          <a href="https://www.facebook.com/gympods">
            <i class="fa-brands fa-facebook"></i></a>
          <a href="https://www.tiktok.com/@gympods">
            <i class="fa-brands fa-tiktok"></i></a>
        </div>

        <h3 style="color: white">Get the app</h3>
        <div id="download-app">
          <a style="padding: 10px;" href='https://play.google.com/store/apps/details?id=com.gympods.app'>
            <img width="150px" class="android" alt='Get it on Google Play' src='../assets/googleplay.png' />
          </a>
          <a style="padding: 10px;" href="https://apps.apple.com/gb/app/gympods/id6502242401">
            <img width="150px" class="apple" src="../assets/appstore.png" alt="Download on the App Store">
          </a>
        </div>
      </div>
    </div>

    <div id="footer-lower">
      <div id="footer-lower-container">
        <div id="left">
          <p>Subscribe to our newsletter</p>
          <button>Sign up</button>
        </div>
        <div id="right">
          <img src="../assets/logoWhite.png" alt="Logo">
          <p>2025 &copy; GYMPODS</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      width: window.innerWidth,
    };
  },
  methods: {
    openFaqs() {
      window.location.href = '/about?faqs=true';
    },
    contact() {
      this.$router.push('/contact');
    },
    terms() {
      this.$router.push('/terms');
    },
    privacy() {
      this.$router.push('/privacy');
    },
  },
}
</script>

<style lang="scss" scoped>
$aqua: #7fc0c3;
$charcoal: #231F20;
$carbon: #58595B;
$zest: #DFF496;
$midnight: #193446;

#footer {
  text-transform: none !important;
  color: white;
  font-size: 0.8em;
  line-height: 1.875em;
  letter-spacing: 0.2em;
  display: block;
  background-color: $aqua;
  width: 100vw;
  justify-content: space-between;

  #footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 50px 0;
    margin: auto;
    box-sizing: border-box;
  }

  #logo {
    margin-bottom: 60px;
  }

  div {
    display: block;
    text-align: left;
    box-sizing: border-box;

    i {
      margin: 20px;
    }
  }

  p {
    margin: 12px 0;
    display: block;
    cursor: pointer;
  }

  h3 {
    text-transform: none;
    font-weight: 500;
  }

  a:hover {
    text-shadow: 0 0 10px #EFAB7D;
  }

  #socials {
    display: flex;
    justify-content: space-between;
    width: 100px;

    a {
      font-size: 2.3em;
    }
  }

  #download-app {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #footer-lower {
    background-color: $midnight;
    color: white;
    width: 100vw;
  }

  #footer-lower-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    color: white;
    max-width: 1000px;
    margin: auto;

    #left {
      p {
        font-size: 1.5em;
        margin-bottom: 20px;
      }

      button {
        background-color: $zest;
        color: $charcoal;
        border: none;
        border-radius: 6px;
        padding: 10px 20px;
        margin: 10px 0;
        cursor: pointer;
      }
    }

    #right {
      img {
        width: 100px;
        margin: 10px 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #footer {
    #footer-container {
      padding: 20px 5%;
      box-sizing: border-box;
    }

    #logo {
      width: 160px !important;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    div {
      i {
        margin: 5px;
      }
    }

    h3 {
      margin-bottom: 0;
      margin-top: 30px;
    }

    #socials {
      a {
        font-size: 2em;
      }
    }

    img {
      width: 120px;
    }

    #footer-lower {
      #footer-lower-container {
        padding: 20px 5%;

        #left {
          width: 45%;

          p {
            font-size: 1.2em;
          }

          button {
            padding: 12px 24px;
            font-size: 1.8em;
          }
        }

        #right {
          display: flex;
          flex-direction: column;
          width: 45%;
          align-items: flex-end;

          img {
            width: 80px;
          }
        }
      }
    }
  }
}
</style>