<template>
  <div class="carousel-container">
    <div class="carousel-track" :style="{ animationDuration: animationDuration + 's' }">
      <div class="carousel-slide" v-for="logo in repeatedLogos" :key="logo.id">
        <img :src="logo.src" :alt="logo.alt" class="logo-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      logos: [
        { id: 1, src: '/assets/womens-fitness.png', alt: 'womens fitness' },
        { id: 2, src: '/assets/womens-health.png', alt: 'womens health' },
        { id: 3, src: '/assets/mens-fitness.png', alt: 'mens health' },
        { id: 4, src: '/assets/mail-online.png', alt: 'mail online' },
      ],
      animationDuration: 10,
    };
  },
  computed: {
    repeatedLogos() {
      // Duplicate the logos array to enable a continuous loop effect
      if (this.mobile) return [...this.logos, ...this.logos];
      return this.logos;
    },
  },
  mounted() {
    this.animationDuration = this.mobile ? 10 : 0;
  },
  watch: {
    mobile() {
      this.animationDuration = this.mobile ? 10 : 0;
    },
  },
};
</script>

<style scoped>
.carousel-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.carousel-track {
  display: flex;
  align-items: center;
  animation: scroll infinite linear;
  width: calc(200%);
}

.carousel-slide {
  display: flex;
  align-items: center;
  margin: 20px;
}

.logo-image {
  width: 100px;
  height: auto;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@media (min-width: 925px) {
  .carousel-track {
    justify-content: center;
  }

  .logo-image {
    width: 140px;
  }
}
</style>
