<template>
  <nav v-if="(this.width > 820)" class="nav">
    <img @click="goHome" src="@/assets/logo_nav.png" alt="GYMPODS" />
    <router-link style="color: white" class="navItem" to="/pricing">Pricing</router-link>
    <router-link style="color: white" class="navItem" to="/about">About</router-link>
    <router-link style="color: white" class="navItem" to="/trainers">Trainers</router-link>
    <router-link style="color: white" class="navItem" to="/faqs">FAQs</router-link>
    <router-link v-if="authed" class="navItem navBook" to="/book">Book POD</router-link>
    <router-link v-else class="navItem navJoin" to="/join">Book POD</router-link>
    <router-link v-if="authed" class="navItem navBook" to="/mypods">My Pods</router-link>
  </nav>
  <div v-else class="nav">
    <i :class="{ 'active': this.nav == false }" id="openNav" @click="toggleNav"><svg width="40" height="24"
        viewBox="0 0 35 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="33" height="1.36364" rx="0.681818" fill="white" stroke="white" stroke-width="0.25" />
        <rect x="1" y="7.81824" width="33" height="1.36363" rx="0.681815" fill="white" stroke="white"
          stroke-width="0.25" />
        <rect x="1" y="14.6366" width="33" height="1.36364" rx="0.681818" fill="white" stroke="white"
          stroke-width="0.25" />
      </svg>
    </i>
    <i :class="{ 'active': this.nav == true }" id="closeNav" @click="toggleNav"> <svg width="24" height="26"
        viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.625" y1="-0.625" x2="32.6451" y2="-0.625"
          transform="matrix(0.692552 0.721368 -0.692552 0.721368 0 1.04163)" stroke="white" stroke-width="1.25"
          stroke-linecap="round" />
        <line x1="0.625" y1="-0.625" x2="32.6451" y2="-0.625"
          transform="matrix(-0.692551 0.721368 0.692552 0.721368 24 1.04163)" stroke="white" stroke-width="1.25"
          stroke-linecap="round" />
      </svg>

    </i>
    <img @click="goHome(); this.nav = false" src="@/assets/logo_nav.png" alt="GYMPODS" />
    <nav :class="{ 'navOpen': this.nav == true }">
      <router-link style="color: white" class="navItem" to="/Pricing"
        @click="this.nav = false">Pricing<span>01</span></router-link>
      <span class="line"></span>
      <router-link style="color: white" class="navItem" to="/about"
        @click="this.nav = false">About<span>02</span></router-link>
      <span class="line"></span>
      <router-link style="color: white" class="navItem" to="/trainers"
        @click="this.nav = false">Trainers<span>03</span></router-link>
      <span class="line"></span>
      <router-link style="color: white" class="navItem" to="/faqs"
        @click="this.nav = false">FAQ<span>04</span></router-link>
      <span class="line"></span>
      <router-link v-if="authed" class="navItem navBook" to="/book" @click="this.nav = false"><a
          class="fa-regular fa-calendar"></a> Book</router-link>
      <router-link v-else class="navItem navJoin" to="/join" @click="this.nav = false">Book POD</router-link>
      <router-link v-if="authed" class="navItem navBook" to="/mypods" @click="this.nav = false"><a
          class="fa-regular fa-user"></a> My Pods</router-link>
    </nav>
    <span v-if="this.nav === true" id="navTint" @click="toggleNav"></span>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      authed: false,
      width: window.innerWidth,
      nav: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    toggleNav() {
      this.nav = !this.nav;
    },
  },
  mounted() {
    if (sessionStorage.getItem('userSession')) {
      this.authed = true;
    } else {
      window.addEventListener('auth', () => {
        this.authed = true;
      });
    }

    window.addEventListener('resize', () => {
      console.log('resize')
      this.width = window.innerWidth;
    });
  },
}
</script>


<style lang="scss">
$aqua: #7fc0c3;
$charcoal: #231F20;
$carbon: #58595B;
$zest: #DFF496;
$midnight: #193446;

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Light.ttf') format('truetype');
  font-weight: lighter;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

#app {
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $charcoal;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.nav {
  position: fixed;
  text-align: right;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  padding: 0 5%;
  box-sizing: border-box;
  background-color: $aqua;
  z-index: 100;

  img {
    position: absolute;
    top: 0;
    left: 5%;
    height: 50px;
    padding: 15px 25px;
    cursor: pointer;
  }
}

.LandingHeaderDiv {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingHeader {
  position: relative;
  color: white;
  font-size: clamp(20px, 10vw, 100px);
  text-transform: uppercase;
  line-height: clamp(20px, 10vw, 100px);
  letter-spacing: 0.2em;
  text-align: left;
  max-width: 1440px;
  display: block;
  position: relative;
  margin: auto;
  padding: 50px;
  padding-top: 100px;
  z-index: 3;
}

.LandingHeaderButton {
  padding: 10px 60px;
  color: white;
  background: none;
  border: solid 3px white;
  font-size: 20px;
  cursor: pointer;
}

.LandingHeaderButton:hover {
  background: white;
  color: $charcoal;
}

h2 {
  color: $charcoal;
  font-style: normal;
  font-size: 30px;
  line-height: 49px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0 0 25px 0;
}

h3,
h4 {
  color: $charcoal;
  font-style: normal;
  font-size: 1.25em;
  line-height: 30px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 20px 0;
}

h4 {
  font-size: 0.8em;
}

p {
  margin: 0.4em 0;
}

a,
a:visited {
  color: inherit;
  text-decoration: inherit;
}

.background-aqua-to-zest {
  background-image: linear-gradient(90deg, $aqua, $zest);
}

.background-zest-to-aqua {
  background-image: linear-gradient(270deg, $aqua, $zest);
}

.width {
  max-width: 1440px;
  margin: 0 auto;
}

.singleColumn {
  max-width: 1440px;
  margin: 0 auto;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
}

.singleColumn.fullWidth {
  max-width: unset;
}

.singleColumn.square {
  max-height: 720px;
  height: 100vw;
  display: grid;
  align-items: center;
  justify-items: center;
}

.singleColumn.narrow {
  max-width: 720px;
}

.singleColumn.padded {
  padding: 25px;
}

.twoColumns {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 1440px;
  width: 100vw;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
}

.twoColumns.twoThirds {
  grid-template-columns: 67% 33%;
}

.twoColumns.fullWidth {
  width: 100vw;
  max-width: 100vw;
}

.carousel .twoColumns {
  display: grid;
}

.carousel .twoColumns {
  filter: unset;
}

.carousel.twoColumnsSquare {
  height: 50vw;
  width: 100vw;
}


.twoColumns>div {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.twoColumns.square>div {
  width: 50vw;
  max-width: 720px;
  max-height: 720px;
  height: 50vw;
}

.whiteBtn {
  background: none;
  border: solid 3px white;
  color: white;
  padding: 10px 60px;
  font-size: 20px;
  cursor: pointer;
}

.whiteBtn:hover {
  background: white;
  color: $charcoal;
}

.blackBtn {
  @extend .whiteBtn;
  border: solid 3px $charcoal;
  color: $charcoal;
}

.blackBtn:hover {
  background: $charcoal;
  color: white;
}

.navItem {
  display: inline-block;
  padding: 27.5px 20px;
  font-size: 1rem;
  transition: all 0.2s;
  cursor: pointer;

  &.router-link-exact-active,
  &:hover {
    text-shadow: 3px 2px 10px #231F20;
  }

  &:last-child {
    margin-right: 50px;
  }
}

.navBook,
.navJoin {
  margin: 0 20px;
  padding: 8px 12px;
  color: #231F20;
  background-color: $zest;
  font-size: 1rem;
  border-radius: 7px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: inline-block;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  border-top: 2px solid #030303;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: auto;
}

@media screen and (max-width: 820px) {
  .nav {

    #openNav,
    #closeNav {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
      color: white;
      padding: 25px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }

    img {
      left: 0;
    }

    nav {
      width: 0px;
      height: calc(100vh - 80px);
      overflow-x: hidden;
      position: fixed;
      top: 80px;
      left: 0;
      transition: 0.2s;
      background-color: $midnight;
      text-align: left;
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      .line {
        width: calc(100% - 40px);
        height: 1px;
        background-color: white;
        margin-left: 20px;
      }

      .navItem {
        font-weight: normal;
        letter-spacing: 0.02em;
        padding-top: 40px;
        padding-bottom: 5px;
        font-size: 1.8em;
        text-transform: uppercase;

        span {
          font-size: 0.6em;
          float: right;
          margin-top: 12px;
        }

        a {
          margin-right: 10px;
        }
      }
    }

    .navOpen {
      width: 100vw;
      z-index: 3;
    }

    .navBook,
    .navJoin {
      border: none;
      color: $charcoal;
      background-color: $zest;
      margin-top: 40px;
      padding: 10px 20px 10px 20px !important;
      width: fit-content;
      text-transform: none !important;
      font-size: 1.4em !important;
    }

    #navTint {
      width: 100vw;
      height: calc(100vh - 80px);
      position: fixed;
      top: 80px;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .LandingHeader,
  .LandingHeaderDiv {
    box-sizing: border-box;
    height: 500px;
    // margin-top: 160px;
    // padding: 0;
  }

  .LandingHeaderButton {
    margin-top: 50px;
  }

  .twoColumns {
    grid-template-columns: unset;

    div {
      width: 100vw;
      height: 100vw;
    }
  }
}
</style>
